(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var menu = function menu() {
  var burger = document.querySelector('.header__menu-toggle');
  burger.addEventListener('click', function () {
    document.body.classList.toggle('nav-active');
  });
};

var fixedHeader = function fixedHeader() {
  var header = document.querySelector('.header');
  window.addEventListener('scroll', function () {
    if (window.pageYOffset !== 0) {
      header.classList.add('header--fixed');
    } else {
      header.classList.remove('header--fixed');
    }
  });
};

menu();
fixedHeader();
/* Sliders */

if (document.body.classList.contains('home')) {
  var heroGallery = tns({
    controlsText: ["", ""],
    container: '.hero__slider',
    loop: true,
    items: 1,
    slideBy: 'page',
    nav: false,
    speed: 500,
    mouseDrag: true,
    disable: true,
    responsive: {
      1024: {
        disable: false
      }
    }
  });
  setTimeout(function () {
    FB.api('/' + fbPageId + '/posts', {
      access_token: fbAPIKey,
      fields: ['full_picture', 'message', 'permalink_url', 'created_time']
    }, function (response) {
      if (response && !response.error) {
        var sliderContainer = document.querySelector('.hero__slider');

        for (var i = 0; i < 3; i++) {
          if (response.data[i].full_picture) {
            var sliderDiv = document.createElement('div');
            sliderDiv.className = 'hero__slider__item';
            sliderDiv.classList.add('hero__slider__item--fb');
            var sliderDivInner = document.createElement('div');
            sliderDivInner.className = 'hero__slider__item__inner';
            /* Additional text */

            var slideBefore = document.createElement('div');
            slideBefore.className = 'hero__slider__item__before';
            slideBefore.textContent = document.getElementById('feed-translation-left').textContent;
            var slideAfter = document.createElement('div');
            slideAfter.className = 'hero__slider__item__after';
            slideAfter.textContent = document.getElementById('feed-translation-right').textContent;
            ;
            var postDiv = document.createElement('a');
            postDiv.className = 'post-card';
            postDiv.href = response.data[i].permalink_url;
            var postImageDiv = document.createElement('div');
            postImageDiv.className = 'post-card__image';
            var postImage = document.createElement('img');
            postImage.setAttribute('src', response.data[i].full_picture);
            postImageDiv.appendChild(postImage);
            postDiv.appendChild(postImageDiv);
            var postMeta = document.createElement('div');
            postMeta.className = 'post-card__meta';
            var postDate = response.data[i].created_time.replace('T', ' ').slice(0, -8);
            postMeta.innerHTML = "<img src=\"/wp-content/themes/dobrakaloria/prod/img/logo-fb.jpg\"><p>Dobra kaloria</p><span>".concat(postDate, "</span>");
            postDiv.appendChild(postMeta);
            var postContent = document.createElement('div');
            postContent.className = 'post-card__content';

            if (response.data[i].message) {
              if (response.data[i].message.length > 250) {
                postContent.appendChild(document.createTextNode(response.data[i].message.substr(0, 250) + '...'));
              } else {
                postContent.appendChild(document.createTextNode(response.data[i].message));
              }
            }

            postDiv.appendChild(postContent);
            sliderDivInner.innerHTML += slideBefore.outerHTML + slideAfter.outerHTML + postDiv.outerHTML;
            sliderDiv.appendChild(sliderDivInner);
            sliderContainer.appendChild(sliderDiv);
          }
        }

        var newHeroGallery = heroGallery.rebuild();
      } else {
        console.log(response.error);
      }
    });
  }, 500);
}

if (document.body.classList.contains('page-template-template-fbfeed')) {
  setTimeout(function () {
    FB.api('/' + fbPageId + '/posts', {
      access_token: fbAPIKey,
      fields: ['full_picture', 'message', 'permalink_url', 'created_time']
    }, function (response) {
      if (response && !response.error) {
        var sliderContainer = document.querySelector('.fb-feeds');

        for (var i = 0; i < 10; i++) {
          if (response.data[i].full_picture) {
            var sliderDiv = document.createElement('div');
            sliderDiv.className = 'fb-feed';
            var sliderDivInner = document.createElement('div');
            sliderDivInner.className = 'fb-feed__inner';
            var postDiv = document.createElement('a');
            postDiv.className = 'post-card';
            postDiv.href = response.data[i].permalink_url;
            var postImageDiv = document.createElement('div');
            postImageDiv.className = 'post-card__image';
            var postImage = document.createElement('img');
            postImage.setAttribute('src', response.data[i].full_picture);
            postImageDiv.appendChild(postImage);
            postDiv.appendChild(postImageDiv);
            var postMeta = document.createElement('div');
            postMeta.className = 'post-card__meta';
            var postDate = response.data[i].created_time.replace('T', ' ').slice(0, -8);
            postMeta.innerHTML = "<img src=\"/wp-content/themes/dobrakaloria/prod/img/logo-fb.jpg\"><p>Dobra kaloria</p><span>".concat(postDate, "</span>");
            postDiv.appendChild(postMeta);
            var postContent = document.createElement('div');
            postContent.className = 'post-card__content';

            if (response.data[i].message) {
              if (response.data[i].message.length > 180) {
                postContent.appendChild(document.createTextNode(response.data[i].message.substr(0, 180) + '...'));
              } else {
                postContent.appendChild(document.createTextNode(response.data[i].message));
              }
            }

            postDiv.appendChild(postContent);
            sliderDivInner.appendChild(postDiv);
            sliderDiv.appendChild(sliderDivInner);
            sliderContainer.appendChild(sliderDiv);
          }
        }
      } else {
        console.log(response.error);
      }
    });
  }, 500);
}

if (document.body.classList.contains('tax-kategorie_produktow')) {
  var mainContainer = document.querySelector('.main');

  if (mainContainer.classList.contains('horizontal-view')) {
    var checkCenterSlide = function checkCenterSlide(slider) {
      var counter = 0;

      for (var i = 0; i < slider.slideItems.length; i++) {
        slider.slideItems[i].classList.remove('center-slide');

        if (slider.slideItems[i].classList.contains('tns-slide-active')) {
          if (counter === 1) {
            slider.slideItems[i].classList.add('center-slide');
          }

          counter++;
        }
      }
    };

    var productsCarousel = tns({
      controlsText: ["", ""],
      container: '.products-slider',
      loop: true,
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 500,
      mouseDrag: true,
      responsive: {
        1000: {
          items: 3
        }
      }
    });
    checkCenterSlide(productsCarousel.getInfo());
    productsCarousel.events.on('indexChanged', function (info) {
      checkCenterSlide(info);
    });
  } else {
    var _productsCarousel = tns({
      controlsText: ["", ""],
      container: '.products-slider',
      loop: true,
      slideBy: 1,
      nav: false,
      speed: 500,
      mouseDrag: true,
      rewind: true,
      // fixedWidth: 260,
      autoWidth: true
    });
  }
}

if (document.body.classList.contains('page-template-template-categories')) {
  var categoriesCarousel = tns({
    controlsText: ["", ""],
    container: '.categories-slider',
    loop: false,
    items: 1,
    slideBy: 1,
    nav: false,
    speed: 500,
    mouseDrag: true,
    disable: true,
    responsive: {
      769: {
        items: 3,
        disable: false
      },
      900: {
        items: 3
      },
      1040: {
        items: 4
      },
      1600: {
        items: 5
      }
    }
  });
}

if (document.body.classList.contains('single-przepis') || document.body.classList.contains('single-post')) {
  var recipeGallery = tns({
    controlsText: ["", ""],
    container: '.recipe__gallery',
    loop: true,
    items: 3,
    slideBy: 1,
    nav: false,
    speed: 500,
    rewind: true,
    mouseDrag: true
  });

  if (jQuery('.recipe__gallery').length > 0) {
    jQuery(function ($) {
      var gallery = $('.recipe__gallery .recipe__gallery__item').simpleLightbox();
    });
  }
}

if (document.body.classList.contains('page-template-template-about')) {
  var teamGallery = tns({
    controlsText: ["", ""],
    container: '.team-slider',
    loop: true,
    items: 1,
    slideBy: 1,
    nav: false,
    speed: 500,
    rewind: true,
    responsive: {
      769: {
        items: 3,
        slideBy: 'page'
      }
    }
  });
  var membersToggles = document.querySelectorAll('.team__member__about__toggle');
  membersToggles.forEach(function (toggle) {
    toggle.addEventListener('click', function () {
      toggle.parentElement.classList.toggle('team__member__about--active');
    });
  });
}
/* Scroll to active tax on mobile */


window.onload = function () {
  if (window.screen.width < 768 && document.body.classList.contains('tax-kategorie_produktow')) {
    var activeTax = document.querySelector('.m-tax--active');

    if (window.screen.width < 400) {
      animate(document.scrollingElement || document.documentElement, "scrollTop", "", 0, activeTax.offsetTop - 94, 500, true);
    } else {
      animate(document.scrollingElement || document.documentElement, "scrollTop", "", 0, activeTax.offsetTop - 110, 500, true);
    }
  }
};
/* AJAX SEARCH */


if (document.body.classList.contains('page-template-template-search')) {
  var searchInput = document.querySelector('.search__input');
  searchInput.addEventListener('keyup', function () {
    if (searchInput.value.length > 2) {
      jQuery.ajax({
        url: '/wp-admin/admin-ajax.php',
        data: {
          action: 'get_ajax_posts',
          search: searchInput.value
        },
        type: 'POST',
        beforeSend: function beforeSend() {
          jQuery('.search__results').html('');
        },
        success: function success(response) {
          jQuery('.search__results').html(response);
        }
      });
    } else {
      jQuery('.search__results').html('');
    }
  });
}
/* Functions */


function animate(elem, style, unit, from, to, time, prop) {
  if (!elem) {
    return;
  }

  var start = new Date().getTime(),
      timer = setInterval(function () {
    var step = Math.min(1, (new Date().getTime() - start) / time);

    if (prop) {
      elem[style] = from + step * (to - from) + unit;
    } else {
      elem.style[style] = from + step * (to - from) + unit;
    }

    if (step === 1) {
      clearInterval(timer);
    }
  }, 25);

  if (prop) {
    elem[style] = from + unit;
  } else {
    elem.style[style] = from + unit;
  }
}

},{}]},{},[1]);
